/*
 * NOTE: This utility file is to avoid circular dependencies with apple_healthkit_sync.worker.ts and apple_healthkit.ts.
 */
import settings_store from "$lib/client/indexeddb/settings_store";

/*
 * As documented by: https://developer.apple.com/documentation/healthkit/hkworkoutactivitytype
 */ 
export type AppleHealthKitActivityType = 'swimming' | 'swimBikeRun' | 'other';
export const APPLE_HEALTHKIT_ACTIVITY_TYPE_LOOKUP: {[type: number]: AppleHealthKitActivityType} = {
    46: 'swimming',
    82: 'swimBikeRun'
};

/* 
 * As documented by: https://developer.apple.com/documentation/healthkit/hkworkoutswimminglocationtype
 */
export type AppleHealthKitSwimLocationType = 'openWater' | 'pool' | 'unknown';
export const APPLE_HEALTHKIT_SWIM_LOCATION_TYPE_LOOKUP: {[type: number]: AppleHealthKitSwimLocationType} = {
    0: 'unknown',
    1: 'pool',
    2: 'openWater'
};

/**
 * Apple HealthKit will periodically delete location data. This information is lost permanently.
 * For these cases, there is nothing we can do, so this is not really an error as much as it
 * is a limitation of the data source.
 * 
 * The following represents the maximum age an AHK workout can have before *missing* data is considered 
 * part of the normal AHK data retention policy. Missing data on any workouts younger than
 * this threshold is considered an error. 
 */
export const AHK_DATA_RETENTION_AGE_THRESHOLD_IN_MILLIS = 1000 * 60 * 60 * 24 * 365 * 1; // 1 year

const SYNC_PROCESS_STATE_STALE_TIMEOUT = 1000 * 60 * 5;
export type SyncProcessorStatus = 'idle' | 'busy';

export async function update_sync_processor_status(status: SyncProcessorStatus) {
    await settings_store.put_setting('device_activity_sync_processor_status', {value_string: status});
}

/**
 * If the sync processor status records are stale (ie. interrupted and past expiration threshold) - reset the state to enable processing 
 * to start again.
 */
export async function get_fixed_sync_process_state(): Promise<SyncProcessorStatus> {

    let sync_processor_status = await settings_store.get_setting('device_activity_sync_processor_status');
    if (sync_processor_status && sync_processor_status.value_string === 'busy') {

        let last_update_timestamp = sync_processor_status.last_update_timestamp;
        let age = (new Date()).getTime() - last_update_timestamp.getTime();
        if (age > SYNC_PROCESS_STATE_STALE_TIMEOUT) {

            console.log("Fixing stale sync processor status.");
            await update_sync_processor_status('idle');
            return 'idle';
        } else {
            console.log("NOT fixing stale sync processor status.");
        }
    }

    let to_return: SyncProcessorStatus | undefined = sync_processor_status?.value_string as SyncProcessorStatus;
    return to_return ?? 'idle';
}

function get_ahk_location_types(ahk: any): Set<AppleHealthKitSwimLocationType> {

    let location_types = new Set<AppleHealthKitSwimLocationType>();
    for (let hk_activity of ahk.HKWorkoutActivities) {
       
        let activity_location_type: AppleHealthKitSwimLocationType = 
           APPLE_HEALTHKIT_SWIM_LOCATION_TYPE_LOOKUP[hk_activity.HKSwimLocationType] ?? 'unknown';
        location_types.add(activity_location_type);
    }

    return location_types;
}

export function is_ahk_swim(ahk: any): boolean {

    let location_types = get_ahk_location_types(ahk);
    // console.log("---------> Location Types: ", location_types);
    return location_types.has('pool') || location_types.has('openWater');
}

// Quick validation check to determine if records are missing (which happens due to AHK's data retention policy)
export function is_ahk_incomplete(ahk: any): boolean {

    let activity_locations = get_ahk_location_types(ahk);
 
    let is_gps_activity = activity_locations.has('openWater');
    if (is_gps_activity) {

        // if no locations, then data is incomplete
        return (ahk.CLLocations ?? []).length < 2;
    }

    // if no events, then data is incomplete
    return (ahk.HKWorkoutActivities ?? []).map((item: any) => item.HKWorkoutEvents ?? []).filter((item: any) => item.length !== 0).length === 0;
}

export function is_ahk_past_data_retention_threshold(ahk: any): boolean {

    let age = new Date().getTime() - new Date(ahk.start_date).getTime();
    // console.log("---------> ", age, AHK_DATA_RETENTION_AGE_THRESHOLD_IN_MILLIS);
    return age > AHK_DATA_RETENTION_AGE_THRESHOLD_IN_MILLIS;
}