import type { OriginalUploadFileType } from '$lib/activity/activity_types';
import { type IDBPDatabase, openDB, type DBSchema } from 'idb';

export type PersistentDeviceActivityStoreEntry = {
    uuid: string, 
    user_account_uuid: string,          
    activity_timestamp: Date,
    activity_end_timestamp: Date,
    sync_status: 'available' | 'processing' | 'processed' | 'failed' | 'unavailable',
    file_type: OriginalUploadFileType,
    payload?: string,
    entity_type?: 'track' | 'pool',
    entity_uuid?: string,
    error_message?: string,
    device_type: 'apple_healthkit',
    creation_timestamp: Date,
    last_update_timestamp: Date,
    s3_key?: string,
};

interface PersistentDatabase extends DBSchema {
    device_activity_sync_state: {
        value: PersistentDeviceActivityStoreEntry;
        key: string; // randomly-generated uuid
    }
}

const PERSISTANT_DATABASE_NAME = 'device_activity_sync_database';
const PERSISTANT_DATABASE_VERSION = 1;

class DeviceActivitySyncStateStore {

    db?: IDBPDatabase<PersistentDatabase>;

    private async open_idb(): Promise<IDBPDatabase<PersistentDatabase>> {

        if (this.db === undefined) {

            this.db = await openDB<PersistentDatabase>(PERSISTANT_DATABASE_NAME, PERSISTANT_DATABASE_VERSION, {
                upgrade(upgrade_db) {
        
                    if (!upgrade_db.objectStoreNames.contains('device_activity_sync_state')) {
                        upgrade_db.createObjectStore('device_activity_sync_state', {keyPath: 'uuid'});
                    }
                }
            });
        }

        return this.db;
    }

    async get_activity_entry(uuid: string): Promise<PersistentDeviceActivityStoreEntry | undefined> {

        const db = await this.open_idb();
        let entry: PersistentDeviceActivityStoreEntry | undefined = await db.get('device_activity_sync_state', uuid);

        return entry;
    }

    async put_activity_entry(entry: PersistentDeviceActivityStoreEntry) {

        const db = await this.open_idb();
        await db.put('device_activity_sync_state', entry);
    }

    async list_activity_entries(): Promise<PersistentDeviceActivityStoreEntry[]> {

        const db = await this.open_idb();
        let entries: PersistentDeviceActivityStoreEntry[] = await db.getAll('device_activity_sync_state');

        return entries;
    }
}

const device_activity_sync_state_store = new DeviceActivitySyncStateStore();
export default device_activity_sync_state_store;