import { writable } from 'svelte/store';

const MAX_IDLE_TIME = 1000 * 60 * 5; // 5 minutes

class ActivitySyncState {

    pending_uuids: string[];
    last_update_timestamp: Date;

    constructor() {
        this.pending_uuids = [];
        this.last_update_timestamp = new Date();
    }

    enqueue(uuids: string[]) {
        this.pending_uuids = [...this.pending_uuids, ...uuids];
        this.last_update_timestamp = new Date();
    }

    complete(uuids: string[]) {

        this.pending_uuids = this.pending_uuids.filter(uuid => !uuids.includes(uuid));
        this.last_update_timestamp = new Date();
    }

    get active() {

        // if we haven't been updated in a while, we're not active
        if (this.last_update_timestamp.getTime() < (new Date().getTime() - MAX_IDLE_TIME)) {
            return false;
        }

        return this.pending_uuids.length > 0;
    }
}

function build_store() {

    let state: ActivitySyncState = new ActivitySyncState();
    const { subscribe, update } = writable(state);

    return {
        subscribe,
        active: () => state.active,
        enqueue: (uuids: string[]) => 
            update((state: ActivitySyncState) => { state.enqueue(uuids);
                                                   return state; }),
        complete: (uuids: string[]) => 
            update((state: ActivitySyncState) => { state.complete(uuids);
                                                   return state; }),
    };       
};

export const activity_sync_store = build_store();